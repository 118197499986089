export const DONATION_FREQUENCY = {
    ONCE: 'once',
    MONTHLY: 'monthly',
};

export const CREDIT_CARD_PROVIDER = {
    AuthorizeNet: 'authorize-net',
    IPG: 'ipg',
    Bambora: 'bambora',
};

export const PAYMENT_TYPE = {
    CREDIT_CARD: 'Credit Card',
    DIRECT_DEBIT: 'Direct Debit',
    PAYPAL: 'Paypal',
};
